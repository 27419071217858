import React, { useState, useEffect } from 'react';
import './App.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function App() {
  const [transactions, setTransactions] = useState([]);
  const [recipient, setRecipient] = useState('');

  useEffect(() => {
    fetch(`https://icedrophash.chirpin.space/transactions?recipient=${recipient}`)
      .then(response => response.json())
      .then(data => {
        const formattedTransactions = data.map((transaction, index) => {
          // Extract recipients and amounts
          const details = transaction['Decoded Input Details'];
          const recipientsMatch = details.match(/'recipients':\s+\[(.*?)\]/);
          const amountsMatch = details.match(/'amounts':\s+\[(.*?)\]/);

          if (recipientsMatch && amountsMatch) {
            const recipients = recipientsMatch[1].split(",").map(recipient => recipient.replace(/'/g, '').trim());
            const amounts = amountsMatch[1].split(",").map(amount => parseFloat(amount.replace(/'/g, '').trim()) / 10 ** 18);

            // Check if the number of recipients matches the number of amounts
            if (recipients.length === amounts.length) {
              // Pair recipients with corresponding amounts
              const pairedData = recipients.map((recipient, index) => ({
                recipient,
                amount: amounts[index].toLocaleString(undefined, { maximumFractionDigits: 2 }) // Format amount with commas for thousands and round to 2 decimal places
              }));
              
              return {
                id: index + 1,
                transactionHash: transaction['Transaction Hash'],
                pairedData
              };
            } else {
              console.error('Number of recipients does not match number of amounts');
              return null;
            }
          } else {
            console.error('Recipients or amounts not found in input details');
            return null;
          }
        }).filter(transaction => transaction !== null); // Filter out transactions where recipients and amounts couldn't be extracted

        setTransactions(formattedTransactions);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [recipient]);

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };

  return (
    <div className="container">
      <h1>ICE Airdrop Lookup</h1>
      <p>Disclaimer: This website is unrelated to ICE NETWORK or any frozen substance lol. My curiosity lies in understanding the mechanics of executing an airdrop without leaving a direct transaction history in the wallets of the recipients.</p>
      <div className="search-box">
        <label htmlFor="recipient">Wallet address:</label>
        <input type="text" id="recipient" value={recipient} onChange={handleRecipientChange} />
      </div>
      {transactions.map(transaction => (
        <Accordion key={transaction.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${transaction.id}-content`}
            id={`panel-header`}
          >
            <h3>Airdrop {transaction.id} </h3> <p>Tx hash: <a href={`https://bscscan.com/tx/${transaction.transactionHash}`} target="_blank" rel="noopener noreferrer"><span>{transaction.transactionHash}</span></a></p>
          </AccordionSummary>
          <AccordionDetails>
            <table className="transaction-table">
              <thead>
                <tr>
                  <th>Recipients</th>
                  <th>Amounts</th>
                </tr>
              </thead>
              <tbody>
                {transaction.pairedData.map((data, index) => (
                  <tr key={index}>
                    <td><a href={`https://bscscan.com/token/0xc335df7c25b72eec661d5aa32a7c2b7b2a1d1874?a=${data.recipient}`} target="_blank" rel="noopener noreferrer">{data.recipient}</a></td>
                    <td className="amount">{data.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default App;
